<template>
  <section v-if="sidebarComponents.length === 0" class="n-section-primary tw-relative" :style="`background-color: var(--c-${vars.backgroundColor}) `">
    <div class="tw-text-center md:tw-pb-8">
      <h2 v-if="vars.titleText" class="tw-pb-6" style="color: var(--c-text-primary)">
        {{ vars.titleText }}
      </h2>
      <div v-else-if="vars.titleHtml" v-html="vars.titleHtml" class="tw-pb-6"></div>
      <nuxt-link v-if="variant === variants.v1" to="#" :external="true" :class="`n-${vars.buttonTypeText}`">
        {{ vars.buttonLabelText }}
      </nuxt-link>
    </div>
    <div v-if="isBlogsLoading">
      <loader loader-color="var(--c-primary)" />
    </div>
    <div class="tw-relative tw-w-full tw-max-w-[450px] md:tw-max-w-none">
      <Swiper
        :modules="[SwiperPagination]"
        :slides-per-view="3"
        :space-between="30"
        effect="fade"
        :clickable="true"
        :breakpoints="{
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 24,
          },
          1336: {
            slidesPerView: 3,
            spaceBetween: 24,
          },
        }"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
        class="tw-w-full"
      >
        <SwiperSlide
          v-for="(blog, index) in blogs"
          :key="`blog-${index}`"
          class="tw-flex tw-items-center tw-justify-center"
          style="border-radius: var(--rounded)"
          :delay="index * 250"
        >
          <Theme4BlogsBlogCard :blog="blog" :index="index" />
        </SwiperSlide>
      </Swiper>
      <LibrarySliderPagination :swiper="swiper" variant="v3" :can-paginate-back="canPaginateBack" :can-paginate-next="canPaginateNext" />
    </div>
    <nuxt-link
      v-if="variant === variants.v2 && vars.buttonLabelText"
      to="#"
      :external="true"
      :class="`n-${vars.buttonTypeText} tw-mt-6 md:tw-mt-10`"
      >{{ vars.buttonLabelText }}</nuxt-link
    >
  </section>

  <template v-else-if="sidebarComponents.length > 0">
    <div
      class="tw-relative tw-w-full tw-px-4 tw-pt-8"
      style="border-radius: var(--rounded)"
      :style="`background-color: var(--c-${vars.backgroundColor})`"
    >
      <h3>{{ vars.titleText }}</h3>
      <Swiper
        :modules="[SwiperPagination]"
        :slides-per-view="1"
        :space-between="20"
        effect="fade"
        :clickable="true"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
        class="tw-w-full"
      >
        <SwiperSlide
          v-for="(blog, index) in blogs"
          :key="`blog-${index}`"
          class="tw-flex tw-items-center tw-justify-center"
          style="border-radius: var(--rounded)"
          :delay="index * 250"
        >
          <Theme4BlogsBlogCard :blog="blog" :index="index" />
        </SwiperSlide>
      </Swiper>
      <LibrarySliderPagination :swiper="swiper" variant="v3" :can-paginate-back="canPaginateBack" :can-paginate-next="canPaginateNext" />
    </div>
  </template>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import { useWebsiteStore } from '~/units/website/store';
import type { Component } from '~/types';
import Loader from '~/components/common/Loader.vue';
import NotFound from '~/components/theme2/base/NotFound.vue';
import indexGlobal from '~/mixins/index.global';
import { BlogsMixin } from '~/units/blogs/mixins/blogs.mixin';
import { ComponentMixin } from '~/mixins/component.mixin';
import { useBlogsStore } from '~/units/blogs/store';
import SearchBox from '~/components/common/SearchBox.vue';
import { ROUTES } from '~/constants/ui.constants';

export default defineNuxtComponent({
  name: 'Theme4BlogsBox',
  components: { SearchBox, NotFound, Loader },

  mixins: [BlogsMixin, indexGlobal, ComponentMixin],

  async setup(props: { component: Component }) {
    const blogsStore = useBlogsStore();
    await blogsStore.fetchBlogsInPage(props.component);
  },

  data() {
    return {
      swiper: null,
      canPaginateBack: true,
      canPaginateNext: true,
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },

  mounted() {
    this.onSlideChange();
  },

  computed: {
    ...mapState(useWebsiteStore, ['sidebarComponents']),

    ROUTES() {
      return ROUTES;
    },

    ctaText() {
      return this.vars.ctaText || 'View All Blogs';
    },

    buttonType() {
      return this.vars.buttonTypeText || 'primary';
    },

    backgroundColor() {
      return this.vars.backgroundColor || 'section_primary';
    },

    isLatestCarouselEnabled() {
      return this.vars.isLatestCarouselEnabledNumber;
    },

    isLatestCarouselVisible() {
      const route = useRoute();
      return this.isLatestCarouselEnabled && this.blogs.length !== 0 && !route.query.q && !route.query.page;
    },

    titleHtml() {
      return this.vars.titleHtml;
    },
  },

  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },

    onSlideChange() {
      if (!this.swiper) return;
      const isAtStart = this.swiper.isBeginning;
      const isAtEnd = this.swiper.isEnd;
      if (isAtStart) this.canPaginateBack = false;
      else this.canPaginateBack = true;
      if (isAtEnd) this.canPaginateNext = false;
      else this.canPaginateNext = true;
    },
  },
});
</script>
